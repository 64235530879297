import { Global } from '@emotion/react'
import { useAuth } from 'contexts/authentication-context'
import { navigate } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'theme-ui'
import { config, EnvVariable } from 'utils/config'
import BlankLayout from '../layouts/blank'
import SEO from '../seo'

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

let OktaSignIn: any

// Work around for Gatsby static files build
if (typeof window !== 'undefined') {
  OktaSignIn = require('@okta/okta-signin-widget/dist/js/okta-sign-in.min')
}

import Axios, { AxiosInstance } from 'axios'

const axiosInstance: AxiosInstance = Axios.create({
  timeout: 7000,
  headers: {
    'Content-Type': 'application/json'
  },
})

import * as $ from 'jquery';

const LoginPage = () => {
  const { isAuthenticated } = useAuth()
  const signIn = useRef<any>()

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof OktaSignIn !== 'undefined') {
      const t = new Date();
      axiosInstance
      .get<any>(`/environment.json?bootstrap=1&t=${t.getTime()}`, {})
      .then((response) => {
        const env = (window as any).env = response.data || {};
        signIn.current = new OktaSignIn({
          baseUrl: env.signInBaseUrl,
          features: {
            registration: true,
          },
          i18n: {
            en: {
              'primaryauth.title': ' ',
            },
          },
          logo: '/logo.inline.svg',
        });

        signIn.current?.renderEl(
          { el: '#sign-in-widget' },
          (res: any) => {

            const form = $('<form>', {
              method: 'POST',
              // action: '/auth/okta-widget'
              action: '/login'
            });

            $(document.body).append(form);

            const sessionTokenField = $('<input type="hidden" name="sessionToken"/>');
            const csrfTokenField = $(`<input type="hidden" name="_csrf" value="${(window as any).env.csrfToken}"/>`);

            sessionTokenField.val(res.session.token);

            form.append(sessionTokenField);
            form.append(csrfTokenField);
            form.submit();

            /*
            axiosInstance
            .post<any>(`/auth/okta-widget`, {
              sessionToken: res.session.token,
              _csrf: (window as any).env.csrfToken
            })
            .then((response) => {
            })
            .catch(err => {
              throw err;
            });
            */
          },
          (err: any) => {
            throw err;
          }
        );
      });

      return () => {
        if (signIn && signIn.current && signIn.current.remove) {
          signIn.current.remove();
        }
      }
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/')
    }
  }, [isAuthenticated])

  return (!isAuthenticated && (window as any).env) ? (
    <BlankLayout />
  ) : (
    <BlankLayout>
      <SEO title="Facet Program Client Portal" />
      <Global
        styles={(theme: any) => ({
          '#sign-in-widget': {
            '#okta-sign-in': {
              borderRadius: '20px',
              marginTop: 0,
              border: 0,
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
              fontFamily: theme.fonts.body,
            },
            '.registration-container .registration-link': {
              color: theme.colors.primary,
              textDecoration: 'underline',
            },
            '#okta-sign-in.auth-container': {
              '.button-primary': {
                border: '0',
                borderRadius: '4px',
                background: theme.colors.primary,
                fontSize: theme.fontSizes[0],
              },
              h2: {
                fontSize: theme.fontSizes[0],
                fontWeight: 'normal',
              },
            },
            '#okta-sign-in .auth-org-logo': {
              maxHeight: 'unset',
            },
          },
        })}
      />
      <Flex
        sx={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <div id="sign-in-widget" />
        </Box>
      </Flex>
    </BlankLayout>
  )
}

export default LoginPage
