/** @jsx jsx */
import { jsx } from 'theme-ui'
import BlankLayout from '../components/layouts/blank'
import LoginPage from '../components/login'
import SEO from '../components/seo'

const IndexPage = () => {
  const isSSR = typeof window === 'undefined'

  return !isSSR ? (
    <BlankLayout>
      <SEO title="Facet Program Client Portal" />
      <LoginPage />
    </BlankLayout>
  ) : null
}

export default IndexPage
