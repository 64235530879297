/** @jsx jsx */
import "./../normalizer.css"
import { Global } from '@emotion/react'
import { jsx } from "theme-ui"
import { FC } from "react"

const BlankLayout: FC = ({ children }) => {
  return (
    <div>
      <Global
        styles={(theme: any) => ({
          body: {
            color: theme.colors.text,
            backgroundColor: theme.colors.primary,
          }
        })}
      />
      <main>{children}</main>
    </div>
  )
}

export default BlankLayout
